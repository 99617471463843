<!--
 * @Author: goodLiang
 * @Date: 2023-01-12 11:55:31
 * @LastEditors: goodLiang
 * @LastEditTime: 2023-12-13 16:26:37
 * @FilePath: /vue3-template/src/views/home/index.vue
-->
<template>
  <div class="page-title">欢迎来到歌德商家工作台</div>
</template>

<script setup>
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
const store = useStore()
const router = useRouter()
if (store.getters.userInfo.apply_state !== 3) {
  router.push('/enter')
}
</script>

<style lang="scss" scoped>
.page-title {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 30px;
  font-weight: bold;
}
</style>
